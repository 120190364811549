<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    color="primary"
  >
    <CAlert :show.sync="dismissCountDown" color="danger" fade>{{ notification }}</CAlert>
    <div>
      <CCard>
        <CCardBody>
          <div v-if="step === 0">
            <SearchList
              v-if="place.branch_id === null"
              :ph="$t('cloud.title.ph_select_branch')"
              :list="branch_list"
              :pageTotal="branch_page_total"
              @search="onSearchBranch"
              @focus="onFocusBranch"
              @select="onSelectBranch"
              @page="onPageBranch"
            />
            <CCard v-else>
              <CCardBody>
                <div class="my-2">{{$t('cloud.title.branch')}}</div>
                <CWidgetIcon :header="branch.name" color="primary">
                  <CIcon name="cil-room" width="24"/>
                </CWidgetIcon>

                <SearchList
                  v-if="place.section_id === null"
                  :ph="$t('cloud.title.ph_select_section')"
                  :list="section_list"
                  :pageTotal="section_page_total"
                  @search="onSearchSection"
                  @focus="onFocusSection"
                  @select="onSelectSection"
                  @page="onPageSection"
                />
                <div v-else>
                  <div class="my-2">{{$t('cloud.title.section')}}</div>
                  <CWidgetIcon :header="section.name" color="dark">
                    <CIcon name="cil-cursor" width="24"/>
                  </CWidgetIcon>
                  <CButton style="float: right;" color="danger" shape="pill" @click="onReset">{{$t('button.reset')}}</CButton>
                </div>
              </CCardBody>
            </CCard>
          </div>
          <div v-if="step === 1">
            <CInput
              :label="$t('cloud.title.name')"
              horizontal
              v-model="place.name"
            />
          </div>
        </CCardBody>
      </CCard>
    </div>
    <template #header>
      <h6 class="modal-title">{{place.id? $t('cloud.title.edit_something', {something:$t('cloud.title.management_place')}): $t('cloud.title.add_something', {something:$t('cloud.title.management_place')})}}  {{ ` - STEP (${+(step+1)}/2)` }}</h6>
      <CButtonClose @click="show = false" class="text-white"/>
    </template>
    <template #footer>
      <CButton v-if="step===0" @click="onCancel" color="secondary">{{$t('button.cancel')}}</CButton>
      <CButton v-if="step===0" @click="onNext" color="primary">{{$t('button.next')}}</CButton>
      <CButton v-if="step===1 && myAuthorizationLevel.level < 100" @click="onPrev" color="secondary">{{$t('button.prev')}}</CButton>
      <CButton v-if="step===1 && myAuthorizationLevel.level >= 100" @click="onCancel" color="secondary">{{$t('button.cancel')}}</CButton>
      <CButton v-if="step===1" @click="onSubmit" color="primary">{{$t('button.submit')}}</CButton>
    </template>
  </CModal>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import SearchList from '@/components/SearchList.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'PlaceForm',
  components: {
    SearchList
  },

  data() {
    return {
      show: false,

      branch_list: [],
      branch_search: null,
      branch_page_total: 1,
      branch_page_current: 1,

      section_list: [],
      section_search: null,
      section_page_total: 1,
      section_page_current: 1,

      place: {
        id: null,
        name: null,
        branch_id: null,
        section_id: null,
        sid: null
      },
      branch: null,
      section: null,

      notification: '',
      dismissCountDown: 0,
      step: 0
    }
  },
  computed: {
    ...mapGetters([
      'myAuthorizationLevel'
    ]),
    ...mapState([
      'capability'
    ]),
    dangerIfDuplicated() {
      if (this.place.origins.findIndex(el => el.name === this.new_origin) >= 0) {
        return 'danger';
      }
      if (this.new_origin.length > 32) {
        return 'danger';
      }
      return '';
    }
  },
  methods: {
    open(place) {
      this.onCancel();
      if (place) {
        this.place = JSON.parse(JSON.stringify(place));
        this.branch = place.branch;
        this.section = place.section;
      }
      this.show = true;
    },
    onCancel() {
      this.show = false;

      this.branch_list = [];
      this.branch_search = null;
      this.branch_page_current = 1;
      this.branch_page_total = 1;

      this.section_list = [];
      this.section_search = null;
      this.section_page_total = 1;
      this.section_page_current = 1;

      this.place = {
        id: null,
        name: null,
        branch_id: null,
        section_id: null,
        sid: null
      };
      this.branch = null;
      this.section = null;
      this.step = 0;
    },
    onSubmit() {
      if (!this.place.name) {
        this.notification = this.$t('cloud.title.validate.name');
        this.dismissCountDown = 3;
        return;
      }
      if (this.place.name.length > 64) {
        this.notification = this.$t('cloud.title.validate.long_name');
        this.dismissCountDown = 3;
        return;
      }

      this.$emit('payload', this.place);
      this.onCancel();
      this.show = false;
    },
    onNext() {
      if (!this.place.branch_id) {
        this.notification = this.$t('cloud.title.validate.branch');
        this.dismissCountDown = 3;
        return;
      }
      this.step++;
    },
    onPrev() {
      this.step--;
    },

    getBranches(){
      let query_string = '';
      const params = {
        sort: 'name',
        // order: 'desc',
        search: this.branch_search,
        page: this.branch_page_current,
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/branches/${query_string}`)
        .then(result => {
          this.branch_list = result.data.list;
          this.branch_page_total = result.data.page.total;
          this.branch_page_current = result.data.page.current;
        })
        .catch(error => {
          console.error(error);
        });
    },
    onSearchBranch(text) {
      this.branch_search = text;
      this.getBranches();
    },
    onFocusBranch() {
      this.getBranches();
    },
    onPageBranch(p) {
      this.branch_page_current = p;
      this.getBranches();
    },
    onSelectBranch(id) {
      this.place.branch_id = id;
      this.branch = this.branch_list.find(el => el.id === id);
    },
    onReset(){
      this.place.branch_id = null;
      this.branch = null;
      this.branch_list = [];
      this.branch_search = null;
      this.branch_page_total = 1;
      this.branch_page_current = 1;

      this.place.section_id = null;
      this.section = null;
      this.section_list = [];
      this.section_search = null;
      this.section_page_total = 1;
      this.section_page_current = 1;
    },


    getSection(){
      let query_string = '';
      const params = {
        sort: 'name',
        // order: 'desc',
        search: this.section_search,
        page: this.section_page_current,
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/sections/${query_string}`)
        .then(result => {
          this.section_list = result.data.list;
          this.section_page_total = result.data.page.total;
          this.section_page_current = result.data.page.current;
        })
        .catch(error => {
          console.error(error);
        });
    },
    onSearchSection(text) {
      this.section_search = text;
      this.getSection();
    },
    onFocusSection() {
      this.getSection();
    },
    onPageSection(p) {
      this.section_page_current = p;
      this.getSection();
    },
    onSelectSection(id) {
      this.place.section_id = id;
      this.section = this.section_list.find(el => el.id === id);
    },
  }
}
</script>
